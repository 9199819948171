// Mobile Menu

const toggle_sub_menus = document.querySelectorAll('.js-toggle-sub-menu')

for (const toggle_sub_menu of toggle_sub_menus) {
  toggle_sub_menu.addEventListener("click", function () {
    toggle_sub_menu.classList.toggle("sub-menu--open");
  });
}

if (document.querySelector(".js-toggle-mm")) {
  const toggle_mm = document.querySelector('.js-toggle-mm')
  toggle_mm.addEventListener("click", function () {
    let header = document.querySelector('.js-mm');
    let header_offset = document.querySelector('.js-header').getBoundingClientRect().bottom;
    header.style.marginTop = `${header_offset}px`;
    header.style.height = `calc(100vh - ${header_offset}px)`;
    document.body.classList.toggle("mm--open");
  });
}


const triggers = document.querySelectorAll('.js-trigger')

for (const trigger of triggers) {
    trigger.addEventListener('click', () => {
        trigger.classList.toggle('open')
    })
}

const megamenu_trigger_open = document.querySelectorAll('.js-megamenu-open')

for (const trigger of megamenu_trigger_open) {
    trigger.addEventListener('click', (e) => {
        e.preventDefault()
        trigger.classList.toggle('open')
    })
}

// Close old mobile megamenus
// const megamenu_trigger_close = document.querySelectorAll('.js-megamenu-close')

// for (const trigger of megamenu_trigger_close) {
//     trigger.addEventListener('click', () => {
//         const id = trigger.dataset.id;
//         document.querySelector(`.js-megamenu-open[data-id="${id}"]`).classList.remove('open')
//     })
// }

// Localization form submission
if (document.querySelector('.js-mobile-localization-link')) {
  let localization_form = document.querySelector('.js-mobile-localization-form');
  let localization_form_code = document.querySelector('.js-mobile-localization-country-code');
  let localization_links = document.querySelectorAll('.js-mobile-localization-link');
  
  for (let link of localization_links) {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      localization_form_code.value = link.dataset.value;
      localization_form.submit();
    })
  }
}